import {
    AppBar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    fade,
    Grid,
    IconButton,
    Tab,
    Tabs,
    Toolbar,
    Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
    mdiCloseCircleOutline,
    mdiCube,
    mdiFilmstrip,
    mdiImage,
    mdiMusic,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Token } from "@stripe/stripe-js";
import { useSnackbar } from "notistack";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { Pagination } from "../../components/Pagination";
import { USER_ROLE_TYPE } from "../../constants";
import { Asset, Experience } from "../../models/exp-entities";
import { XRAsset } from "../../types/xrEntities";
import { AssetListQuery, POST_PurchaseStoreAsset } from "../../xr-platform-api";
import { authState } from "../account/auth/authStore";
import { PaymentFormDialog } from "../account/stripe/PaymentFormDialog";
import { updatePaymentSourceAPI } from "../account/stripe/stripeAPI";
import { getCustomerPaymentSource } from "../account/stripe/stripeHelpers";
import { useStripeCustomer } from "../account/stripe/useStripeCustomer";
import { updateExperienceAsset } from "../xr_experience/experience-manager";
import { useExperienceSceneManagerContext } from "../xr_experience/experience-scene-manager-context";
import {
    assetStoreState,
    expAssetState,
    experienceState,
    selectedAssetIDState,
} from "../xr_experience/experience-store";
import { useAssetsList } from "../xr_experience/hooks/assetHooks";
import { AssetCard } from "./AssetCard";
import { generateSessionID } from "./assetUtilities";

//const TEAM_ASSET_PER_PAGE = 12;
const STORE_ASSET_PER_PAGE = 12;

const categories = [
    { label: "Images", icon: mdiImage, type: "image" },
    { label: "Videos", icon: mdiFilmstrip, type: "video" },
    { label: "Audio", icon: mdiMusic, type: "audio" },
    { label: "Models", icon: mdiCube, type: "3d" },
];

const useStyles = makeStyles(theme =>
    createStyles({
        content: {
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(4),
            display: "flex",
        },
        actions: {
            padding: 0,
            borderTop: `1px solid ${theme.palette.divider}`,
            boxShadow: theme.shadows[2],
        },
        paper: {
            minWidth: "85%",
            minHeight: "85%",
        },
        title: {
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
        },
        divider: {
            marginLeft: theme.spacing(3),
        },
        tabs: {
            flexGrow: 1,
        },
        tab: {
            minWidth: 80,
        },
        search: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            "&:hover": {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(1),
                width: "auto",
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: "0.5em",
            transition: theme.transitions.create("width"),
            width: "20ch",
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: theme.spacing(0.5, 0.25),
        },
        listBox: {
            border: `solid 1px ${theme.palette.divider}`,
        },
        confirmation: {
            zIndex: 9000,
        },
        ntf: {
            width: "100%",
            // height: "100%",
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        metamask: {
            margin: theme.spacing(4, 0, 0),
        },
    })
);

type Props = { type?: string; replaceCurrent?: boolean; parentAssetId?: string } & Omit<
    DialogProps,
    "children" | "BackdropProps" | "style" | "PaperProps"
>;

export const AssetStore: React.FC<Props> = ({
    type,
    parentAssetId,
    replaceCurrent,
    container,
    open,
    ...dialogProps
}) => {
    const styles = useStyles();
    const [openCCForm, setOpenCCForm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const authUser = useRecoilValue(authState);
    const [editor, setEditor] = useRecoilState(experienceState);
    const [tab, setTab] = React.useState(0);
    const [toPurchase, setToPurchase] = React.useState<XRAsset | null>(null);
    const expSceneManager = useExperienceSceneManagerContext();
    const selectedID = useRecoilValue(selectedAssetIDState);
    const assetReplace = useRecoilValue(
        expAssetState({ session_id: selectedID })
    );
    const parentAsset = useRecoilValue(parentAssetId ? expAssetState({ session_id: parentAssetId }) : expAssetState({ session_id: '' }));
    const [store] = useRecoilState(assetStoreState);

    const {
        customer,
        mutate: mutateCustomer,
        isLoading,
    } = useStripeCustomer({
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });

    const [storeAssetsQuery, setStoreAssetsQuery] =
        React.useState<AssetListQuery>({
            add_props: "belongs_to_my_teams,price,preview_image_urls",
            is_for_store: true,
            name: undefined,
            order_by: "name",
            page: 1,
            per_page: STORE_ASSET_PER_PAGE,
            reverse: false,
            tag: "premium",
            type: categories[0].type,
        });

    const prefetchStoreAssetsQuery = React.useMemo(
        () => ({ ...storeAssetsQuery, page: storeAssetsQuery.page! + 1 }),
        [storeAssetsQuery]
    );

    //const [teamAssetsQuery, setTeamAssetsQuery] = React.useState<AssetListQuery>({
    //    add_props: "belongs_to_my_teams,preview_image_urls",
    //    name: undefined,
    //    order_by: undefined,
    //    page: 1,
    //    per_page: TEAM_ASSET_PER_PAGE,
    //    reverse: true,
    //    tag: "premium",
    //    type: undefined,
    //    team_id: authUser!.team_id,
    //});

    //const prefetchTeamAssetsQuery = React.useMemo(
    //    () => ({ ...teamAssetsQuery, page: teamAssetsQuery.page! + 1 }),
    //    [teamAssetsQuery]
    //);

    const {
        assets: storeAssets,
        page: storeAssetsPage,
        totalCount: totalStoreAssetsCount,
    } = useAssetsList(storeAssetsQuery);
    useAssetsList(prefetchStoreAssetsQuery);

    //const {
    //    assets: teamAssets,
    //    page: teamAssetsPage,
    //    totalCount: totalTeamAssetsCount,
    //    mutate: mutateUserAssets,
    //} = useAssetsList(teamAssetsQuery);
    //useAssetsList(prefetchTeamAssetsQuery);

    const handleStoreAssetsPageTurn = (pageOffset: number) => {
        setStoreAssetsQuery(prev => ({ ...prev, page: prev.page! + pageOffset }));
    };

    const handleBuy = async (assetToBuy: XRAsset) => {
        const price = assetToBuy.price ?? 0;
        if (authUser!.role_type_id >= USER_ROLE_TYPE.SUPER_CREATOR && price > 0) {
            enqueueSnackbar("Only account admins can make purchases.", {
                variant: "error",
            });
            return;
        }
        if (assetToBuy.price !== undefined && assetToBuy.price === 0) {
            if (isLoading) return;
            let key: any;
            try {
                setLoading(true);
                key = enqueueSnackbar(`Importing item`, {
                    variant: "info",
                    persist: true,
                });
                const { asset } = await POST_PurchaseStoreAsset(assetToBuy.uuid);
                handleImport(asset);
                //mutateUserAssets();
            } catch (reason) {
                console.error(reason);
            } finally {
                setLoading(false);
                closeSnackbar(key);
            }
        } else if (getCustomerPaymentSource(customer) === undefined) {
            setOpenCCForm(true);
            return;
        } else {
            setToPurchase(assetToBuy);
        }
    };

    const handleBuyConfirmation = async () => {
        if (toPurchase === null || isLoading) return;
        let key: any;
        try {
            setLoading(true);
            key = enqueueSnackbar(`Processing purchase`, {
                variant: "info",
                persist: true,
            });
            const { asset } = await POST_PurchaseStoreAsset(toPurchase.uuid);
            handleImport(asset);
            setToPurchase(null);
            //mutateUserAssets();
        } catch (reason) {
            console.error(reason);
        } finally {
            setLoading(false);
            closeSnackbar(key);
        }
    };

    const handleBuyRejection = () => {
        if (isLoading) return;
        setToPurchase(null);
    };

    const updateExperienceAssetAudio = (exp: Experience, parentId: string | undefined, childAsset: Asset) => {
        const i = exp.asset_transform_info.findIndex(
            a => a.session_id === parentId
        );
        if (i !== -1) {
            const asset = exp.asset_transform_info[i];
            if (asset.audio) {
                const updatedAsset = {
                    ...asset,
                    audio: {
                        ...asset.audio,
                        uuid: childAsset.uuid
                    }
                }
                const expUpdate = {
                    ...exp,
                    asset_transform_info: [
                        ...exp.asset_transform_info.slice(0, i),
                        updatedAsset,
                        ...exp.asset_transform_info.slice(i + 1),
                        childAsset
                    ],
                    asset_uuids: exp.asset_uuids!
                        .concat([childAsset.uuid])
                }

                return expUpdate;
            }
        }

        return exp;
    };

    const addUpdateAsset = (toImport: Asset) => {
        if (assetReplace && replaceCurrent) {
            return {
                ...updateExperienceAsset(editor!, toImport),
                asset_uuids: editor!
                    .asset_uuids!.filter(uuid => uuid !== assetReplace.uuid)
                    .concat([toImport.uuid]),
            }
        }
        else if (parentAsset && toImport.type === 'audio') {
            return {
                ...updateExperienceAssetAudio(editor!, assetReplace?.session_id, toImport),
                asset_uuids: editor!
                    .asset_uuids!
                    .concat([toImport.uuid]),
            }
        }
        return {
            ...editor!,
            asset_uuids: [...(editor?.asset_uuids ?? []), toImport.uuid],
            asset_transform_info: [
                ...(editor?.asset_transform_info ?? []),
                toImport,
            ],
        }
    }

    const handleImport = async (toImport: Asset) => {
        toImport = { ...toImport };
        if (toImport.type === "audio") {
            toImport.original_width = 0.25;
            toImport.original_height = 0.25;
        }
        toImport.position =
            assetReplace && replaceCurrent
                ? assetReplace.position
                : {
                    x: 0,
                    y: 0,
                    z: toImport.type === "3d" ? -0.05 : -0.01,
                };
        toImport.rotation =
            assetReplace && replaceCurrent
                ? assetReplace.rotation
                : { x: 0, y: 0, z: 0, w: 1 };
        toImport.scale =
            assetReplace && replaceCurrent
                ? assetReplace.scale
                : { x: 1, y: 1, z: 1 };
        toImport.strPos =
            assetReplace && replaceCurrent
                ? assetReplace.strPos
                : ["0", "0", toImport.type === "3d" ? "-0.05" : "-0.01"];
        toImport.strRot =
            assetReplace && replaceCurrent
                ? assetReplace.strRot
                : ["0", "0", "0", "1"];
        toImport.strScl =
            assetReplace && replaceCurrent ? assetReplace.strScl : ["1", "1", "1"];
        toImport.session_id =
            assetReplace && replaceCurrent
                ? assetReplace.session_id
                : generateSessionID();
        const update = addUpdateAsset(toImport);
        setEditor(update);
        expSceneManager?.updateSceneWithStateAsync(update);
        if (dialogProps.onClose) dialogProps.onClose({}, "escapeKeyDown");
        enqueueSnackbar(`${toImport.name} added`, { variant: "info" });
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setStoreAssetsQuery(prev => ({
            ...prev,
            page: 1,
            type: categories[newValue].type,
        }));
        setTab(newValue);
    };

    const handleClose = () => {
        if (dialogProps.onClose) {
            dialogProps.onClose({}, "escapeKeyDown");
        }
    };

    const handlePaymentFormSubmit = (token: Token) => {
        mutateCustomer(async () => {
            try {
                setLoading(true);
                const udpatedCustomer = await updatePaymentSourceAPI(
                    token,
                    authUser!.email!
                );
                setOpenCCForm(false);
                enqueueSnackbar("Payment source added", { variant: "success" });
                return udpatedCustomer;
            } catch (reason) {
                console.error(reason);
                enqueueSnackbar(JSON.stringify(reason), { variant: "error" });
            } finally {
                setLoading(false);
            }
        });
    };

    return (
        <React.Fragment>
            <PaymentFormDialog
                paymentSource={getCustomerPaymentSource(customer)}
                onSubmit={handlePaymentFormSubmit}
                open={openCCForm}
                isLoading={loading}
                onClose={() => setOpenCCForm(false)}
            />
            <ConfirmationDialog
                open={Boolean(toPurchase)}
                title="Confirm Purchase"
                message="You sure?"
                yesColor="primary"
                onYesClick={handleBuyConfirmation}
                onNoClick={handleBuyRejection}
                isLoading={isLoading}
            />
            <Dialog
                BackdropProps={{
                    style: container ? { position: "absolute" } : undefined,
                }}
                style={{ position: container ? "absolute" : undefined, minWidth: 630 }}
                PaperProps={{ classes: { root: styles.paper } }}
                open={open && !openCCForm}
                {...dialogProps}
            >
                <AppBar component="div" position="sticky" color="primary">
                    <Toolbar variant="dense">
                        <Typography className={styles.title} variant="h6">
                            {authUser && authUser.role_type_id >= USER_ROLE_TYPE.SUPER_CREATOR
                                ? "Team Library"
                                : "Media Library"}
                        </Typography>
                        <Tabs
                            className={styles.tabs}
                            value={tab}
                            variant="scrollable"
                            scrollButtons="on"
                            onChange={handleTabChange}
                        >
                            {categories.filter(a => store.type === 'All' || a.type === store.type).map((c, i) => (
                                <Tab
                                    className={styles.tab}
                                    key={i}
                                    label={c.label}
                                    wrapped
                                    icon={<Icon path={c.icon} size={1} />}
                                />
                            ))}
                        </Tabs>
                        <IconButton
                            color="inherit"
                            size="small"
                            onClick={handleClose}
                            style={{ margin: "0 0 0 16px" }}
                        >
                            <Icon path={mdiCloseCircleOutline} size={1.2} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent className={styles.content}>
                    <Grid id="assets-for-purchase" container spacing={2}>
                        {storeAssets.map(a => (
                            <Grid key={a.id} item xs={6} sm={4} md={3} lg={2}>
                                <AssetCard
                                    asset={a}
                                    forStore
                                    onPurchase={handleBuy}
                                    onImport={handleImport}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions className={styles.actions}>
                    <Pagination
                        page={
                            storeAssetsPage
                        }
                        perPage={
                            STORE_ASSET_PER_PAGE
                        }
                        totalCount={
                            totalStoreAssetsCount
                        }
                        onPageTurn={
                            handleStoreAssetsPageTurn
                        }
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
