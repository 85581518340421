import { DefaultLoadingScreen } from "@babylonjs/core";

import logoRing from "../resources/img/loadRing.svg";
import logoSpinner from "../resources/img/loadSpinner.svg";

DefaultLoadingScreen.prototype.displayLoadingUI = function () {
  if (this._loadingDiv) {
    const enableDarkMode = JSON.parse(
      window.localStorage.getItem("dark-mode-enabled") ?? "false"
    );
    this._loadingDiv.style.backgroundColor = enableDarkMode
      ? "#303030"
      : "#f0f0f0";
    return;
  }
  this._loadingDiv = document.createElement("div");
  this._loadingDiv.id = "babylonjsLoadingDiv";

  this._loadingDiv.style.width = "100%";
  this._loadingDiv.style.height = "100%";

  this._loadingDiv.style.display = "flex";
  this._loadingDiv.style.flexDirection = "column";
  this._loadingDiv.style.alignItems = "center";
  this._loadingDiv.style.justifyContent = "center";
  this._loadingDiv.style.opacity = "0";
  this._loadingDiv.style.transition = "opacity 1.5s ease";
  this._loadingDiv.style.pointerEvents = "none";

  var style = document.createElement("style");
  style.type = "text/css";
  var keyFrames =
    "@-webkit-keyframes spin1 { 0% { -webkit-transform: rotate(0deg);}\n                    100% { -webkit-transform: rotate(360deg);}\n                }                @keyframes spin1 {                    0% { transform: rotate(0deg);}\n                    100% { transform: rotate(360deg);}\n                }";
  style.innerHTML = keyFrames;
  document.getElementsByTagName("head")[0].appendChild(style);
  // Loading images

  var box = document.createElement("div");
  box.id = "spinner-box";
  box.style.display = "flex";
  box.style.position = "relative";
  box.style.alignItems = "center";
  box.style.justifyContent = "center";

  var spinner = new Image();
  spinner.src = logoSpinner;
  spinner.style.position = "absolute";
  spinner.style.display = "block";
  spinner.style.width = "150px";
  spinner.style.height = "150px";
  spinner.style.zIndex = 7000;
  spinner.style.animation = "spin1 2s infinite ease-in-out";
  spinner.style.webkitAnimation = "spin1 2s infinite ease-in-out";
  spinner.style.transformOrigin = "50% 50%";
  spinner.style.webkitTransformOrigin = "50% 50%";
  box.appendChild(spinner);

  var ring = new Image();
  ring.src = logoRing;
  ring.style.display = "block";
  ring.style.width = "125px";
  ring.style.height = "125px";
  ring.style.zIndex = 7100;
  box.appendChild(ring);

  this._loadingDiv.appendChild(box);

  this._resizeLoadingUI();
  window.addEventListener("resize", this._resizeLoadingUI);
  const enableDarkMode = JSON.parse(
    window.localStorage.getItem("dark-mode-enabled") ?? "false"
  );
  this._loadingDiv.style.backgroundColor = enableDarkMode
    ? "#303030"
    : "#f0f0f0";
  document.body.appendChild(this._loadingDiv);
  this._loadingDiv.style.opacity = "1";
};
