export enum USER_ROLE_TYPE {
  SUPER_ADMIN = 1,
  ADMIN,
  SUPER_CREATOR,
  CREATOR,
  SUPER_VIEWER,
  VIEWER,
}

export const DOMAIN = process.env.REACT_APP_DOMAIN!;

//Endpoint Defaults
export const MAX_MARKER_QUERY_WIDTH = 2500;
export const MAX_AR_IMG_QUERY_WIDTH = 500;
export const DEFAULT_PER_PAGE = 24;

//Editor Scene
export const SCN_CLEAR_COLOR_DARK = "#4E3A82FF"; // purple
export const SCN_CLEAR_COLOR_LIGHT = "#5B7EA3FF"; // blue
export const SKY_BOX_SIZE = 20;
export const GROUND_SIZE = 8;

// ArcRotateCamera defaults
export const DEFAULT_ALPHA = -Math.PI / 2;
export const DEFAULT_BETA = Math.PI / 2;
export const DEFAULT_RADIUS = 5;
export const DEFAULT_LOWER_RADIUS_LIMIT = 0.15;
export const DEFAULT_UPPER_RADIUS_LIMIT = 15;
export const DEFAULT_PANNING_LIMIT = 10;
export const DEFAULT_PANNING_SENSITIVITY = 5000;
export const DEFAULT_WHEEL_PRECISION = 200;
export const DEFAULT_OFFSET = 0.1; // Recommended by BabylonJS

// Gizmo Manager defaults
export const POS_SNAP_DISTANCE = 0.1;
export const ROT_SNAP_DISTANCE = Math.PI / 8;
export const SCL_SNAP_DISTANCE = 0.25;
export const SCL_SENSITIVITY = 4;
export const SCL_BOX_SIZE = 0.025;
export const ROT_SPHERE_SIZE = 0.025;

// File Upload defaults
export const MEGABYTE = 1000000;
export const MRK_FILE_SIZE_UL_LIMIT = 4 * MEGABYTE;
export const IMG_FILE_SIZE_UL_LIMIT = 10 * MEGABYTE;
export const VID_FILE_SIZE_UL_LIMIT = 20 * MEGABYTE;
export const MDL_FILE_SIZE_UL_LIMIT = 20 * MEGABYTE;
export const AUD_FILE_SIZE_UL_LIMIT = 10 * MEGABYTE;
export const VID_360_FILE_SIZE_UL_LIMIT = VID_FILE_SIZE_UL_LIMIT * 15; 

// Stripe
export const YEARLY_PLAN_NAME = "personal/team every 12 months";
export const SEMESTER_PLAN_NAME = "personal/team every 4 months";
export const MONTHLY_PLAN_NAME = "personal/team";
export const BASIC_PLAN_NAME = "basic";
