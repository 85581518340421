import {
  atom,
  AtomEffect,
  DefaultValue,
  selector,
  selectorFamily,
} from "recoil";

import {
  Experience,
  Asset,
  Marker,
  AssetInspectorState,
} from "../../models/exp-entities";

interface EditorHistoryQueue {
  undoHistory: Array<{
    undo: () => Experience;
    redo: () => Experience;
  }>;
  redoHistory: Array<{
    undo: () => Experience;
    redo: () => Experience;
  }>;
}

export let experienceHistory: EditorHistoryQueue = {
  undoHistory: [],
  redoHistory: [],
};

const historyEffect: AtomEffect<Experience | null> = ({ setSelf, onSet }) => {
  onSet((newValue, oldValue) => {
    if (oldValue === null) return;
    if (newValue === null) {
      experienceHistory.undoHistory = [];
      experienceHistory.redoHistory = [];
      return;
    }
    experienceHistory.undoHistory.push({
      undo: () => {
        setSelf(oldValue);
        const popped = experienceHistory.undoHistory.pop();
        if (popped) experienceHistory.redoHistory.push(popped);
        return oldValue as Experience;
      },
      redo: () => {
        setSelf(newValue);
        const popped = experienceHistory.redoHistory.pop();
        if (popped) experienceHistory.undoHistory.push(popped);
        return newValue as Experience;
      },
    });
    experienceHistory.redoHistory = [];
  });
};

export const experienceState = atom<Experience | null>({
  key: "experienceState",
  default: null,
  effects_UNSTABLE: [historyEffect],
});

export const selectedAssetIDState = atom<string>({
  key: "selectedAssetIDState",
  default: "",
});

export const selected360AssetIDState = atom <string>({
    key: "selected360AssetIDState",
    default: ""
})

export const expMarkerState = selector<Marker | null>({
  key: "expMarkerState",
  get: ({ get }) => get(experienceState)?.marker ?? null,
  set: ({ set }, newValue) => {
    set(experienceState, prev => {
      if (newValue instanceof DefaultValue) return newValue;
      if (prev) {
        return { ...prev, marker: newValue };
      }
      return prev;
    });
  },
});

export const assetInspectorState = atom<AssetInspectorState>({
  key: "assetsInspectorState",
  default: { transform: false, playback: false },
});

export const assetStoreState = atom<{
  open: boolean;
  type: string;
  replaceCurrent?: boolean;
}>({
  key: "assetStoreState",
  default: { open: false, type: "All", replaceCurrent: false },
});

export const expAssetsState = selector<Asset[]>({
  key: "expAssetsState",
  get: ({ get }) => get(experienceState)?.asset_transform_info ?? [],
  set: ({ set }, newValue) => {
    set(experienceState, prev => {
      if (newValue instanceof DefaultValue) return newValue;
      if (prev) {
        return { ...prev, asset_transform_info: newValue };
      }
      return prev;
    });
  },
});

export const expAssetState = selectorFamily<
  Asset | null,
  { session_id: string }
>({
  key: "expAssetState",
  get:
    ({ session_id }) =>
    ({ get }) =>
      get(expAssetsState).find(asset => asset.session_id === session_id) ??
      null,
  set:
    ({ session_id }) =>
    ({ set, get }, newValue) => {
      set(expAssetsState, prev => {
        if (newValue instanceof DefaultValue) return newValue;
        const index = prev.findIndex(asset => asset.session_id === session_id);
        if (index > -1) {
          if (newValue === null)
            return prev.filter(asset => asset.session_id !== session_id);
          return [...prev.slice(0, index), newValue, ...prev.slice(index + 1)];
        }
        return prev;
      });
    },
});
