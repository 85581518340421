import axios, { AxiosResponse } from 'axios'
import { PageQuery } from 'common/types/xrp'

import { getFilenameExtension } from './helpers/getFilenameExtension'
import reportServerError from './helpers/reportServerError'
import {
    Asset,
    AssetType,
    Marker,
    Experience,
    ExperienceSettings
} from './models/exp-entities'
import { StripeCoupon } from './models/stripe'
import User from './models/user'
import { Team, TeamInvite, TeamInviteFormData } from './types'
import { API_ROOT } from './config/BackendApi'

export interface XRServerResponse {
    error: string | null
    status: string
}

export interface Pagination {
    page: number
    total_count: number
}

const isBackendError = (
    reason: any
): reason is { response: AxiosResponse<XRServerResponse> } => {
    return reason.response.data.error
}

const getAuthorizationHeader = (token: string | null | undefined) => {
    return token ? { Authorization: `Bearer ${token}` } : {}
}

//#region Authentication
export type LoginAddPropsQuery = 'plan_nickname' | 'settings' | 'taken_slots'

export interface LoginRequest {
    domain: string
    password: string
    email_or_username: string
    remember?: boolean
    remember_duration?: number
}

export interface LoginResponse extends XRServerResponse {
    access_token: string
    refresh_token: string | null
    user: User
}

export const GET_VerifyLogin = async (addPropsQuery?: 'settings') => {
    try {
        const res = await axios.get<LoginResponse>(`${API_ROOT}/api/v2/verify_login`, {
            params: { add_props: addPropsQuery }
        })
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const POST_Login = async (
    req: LoginRequest,
    addPropsQuery?: LoginAddPropsQuery | LoginAddPropsQuery[]
) => {
    try {
        const res = await axios.post<LoginResponse>(`${API_ROOT}/api/v2/login`, req, {
            params: { add_props: addPropsQuery }
        })
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

//#endregion

//#region Team
export interface TeamInviteResponse extends XRServerResponse {
    invite: TeamInvite
}

export const POST_CreateTeamInvite = async (
    teamID: number,
    data: TeamInviteFormData
) => {
    try {
        const res = await axios.post<TeamInviteResponse>(
            `${API_ROOT}/api/v1/team/${teamID}/invite/create`,
            data,
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        return res.data.invite
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export type TeamsQuery = {
    add_props?: string
    id?: number
    is_white_labelling?: boolean
    max_count_experience_min?: number
    max_count_experience_max?: number
    max_count_users_min?: number
    max_count_users_max?: number
    name?: string
    teamname?: string
} & PageQuery

export type PaginatedTeamsResponse = {
    teams: Team[]
} & Pagination &
    XRServerResponse

export const getTeamsApi = (query?: TeamsQuery) =>
    axios
        .get<PaginatedTeamsResponse>(`${API_ROOT}/api/v1/teams`, {
            params: query
        })
        .then(res => res.data)
        .catch(r => {
            if (isBackendError(r)) {
                reportServerError(r)
                throw r.response.data.error
            }
            throw r
        })

export type UserTeamRoleSwapRequest = {
    team_id: number
    role_name: 'admin' | 'creator' | string
}

/**
 *
 * @param id User ID
 * @returns
 */
export const swapUserTeamAndRoleApi = (
    id: number,
    req: UserTeamRoleSwapRequest,
    add_props?: string
) =>
    axios
        .put<XRServerResponse & { user: User }>(
            `${API_ROOT}/api/v1/user/${id}/team_role/swap`,
            req,
            { params: { add_props } }
        )
        .then(res => res.data.user)
        .catch(r => {
            if (isBackendError(r)) {
                reportServerError(r)
                throw r.response.data.error
            }
            throw r
        })

//#endregion

//#region Stripe - Plan/Subscription
export const DELETE_Subscription = async () => {
    try {
        await axios.delete(`${API_ROOT}/api/v1/subscription/delete`)
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const DELETE_SubscriptionUndo = async () => {
    try {
        const res = await axios.delete<XRServerResponse & { subscription: any }>(
            `${API_ROOT}/api/v1/subscription/delete/undo`
        )
        return res.data.subscription
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const DELETE_ReleaseSubscriptionSchedule = async () => {
    try {
        const response = await axios.delete<
            XRServerResponse & { subscription: any }
        >(`${API_ROOT}/api/v1/release_subscription_schedule`)
        return response.data.subscription
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const GET_Coupons = async () => {
    try {
        const res = await axios.get<XRServerResponse & { coupons: StripeCoupon[] }>(
            `${API_ROOT}/api/v1/coupons`
        )
        return res.data.coupons
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const PUT_ApplyCoupon = async (coupon_id: string) => {
    try {
        const res = await axios.put(`${API_ROOT}/api/v1/apply-coupon`, { coupon_id })
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}
//#endregion

//#region Experience
export type ExperienceAddPropsQuery =
    | 'asset_uuids'
    | 'count_views'
    | 'count_views_h'
    | 'file_size'
    | 'file_size_h'
    | 'user_full_name'
    | 'deeplink'
    | 'has_non_premium_assets'
    | `marker.${keyof Marker}`
    | 'max_views_per_24_hours'
    | 'preview_image_urls'
    | 'view_mocks_rate'
    | 'short_code'
    | 'team_logo_image_url'

export interface ExperienceListQuery {
    add_props?: ExperienceAddPropsQuery | ExperienceAddPropsQuery[] // adds one or more comma-separated properties to the output object
    author_name?: string // case-insensitive, matches partials
    can_feature?: boolean
    exclude_deleted_users?: boolean
    exclude_super_users?: boolean
    expand?: string // default "marker"
    id?: number
    is_public?: boolean
    marker_uuid?: string
    name?: string // case-insensitive, matches partials
    not_tag?: string // inverse of tag, case-insensitive, comma-seperated, AND-logic
    omissions?: string // any experience props to be omitted, comma-separated
    order_by?: string // default created
    page?: number
    per_page?: number // default 20
    reverse?: boolean // default false
    showcase_id?: number
    showcase_uuid?: string
    short_code?: string // 5-char base36(id)
    tag?: string | string[]
    team_id?: number
    user_id?: number
    uuid?: string
}

export interface ExperienceResponse extends XRServerResponse {
    experience: Experience
}

export interface ExperienceListResponse extends Pagination, XRServerResponse {
    experiences: Experience[]
}

export interface ExperienceRequest {
    asset_transform_info: Asset[]
    author_name?: string | null
    asset_uuids: string[]
    delete_old_marker?: boolean
    description?: string | null
    is_from_custom?: boolean
    marker_uuid?: string | null
    meta?: any
    name: string
    product_id?: number
    settings?: Partial<ExperienceSettings>
    contact_email?: string
    scene_color?: string
    marker_floor_to_center_height?: number | null
}

export type ExperienceTransferPayload = {
    experience_uuids_user_ids: { experience_uuid: string; user_id: number }[]
}

export const transferExperiencesApi = (payload: ExperienceTransferPayload) =>
    axios
        .put<XRServerResponse>(`${API_ROOT}/api/v1/experiences/transfer`, payload)
        .then(res => res.data)

export const GET_ExperienceList = async (query?: ExperienceListQuery) => {
    try {
        const res = await axios.get<ExperienceListResponse>(`${API_ROOT}/api/v1/experiences`, {
            params: query && {
                ...query,
                add_props: Array.isArray(query.add_props)
                    ? query.add_props.join(',')
                    : query.add_props
            }
        })
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const POST_Experience = async (
    req: ExperienceRequest,
    addPropsQuery?: ExperienceAddPropsQuery | ExperienceAddPropsQuery[],
    onUploadProgress?: (progressEvent: ProgressEvent<EventTarget>) => void
) => {
    try {
        const res = await axios.post<ExperienceResponse>(
            `${API_ROOT}/api/v1/experience/create`,
            req,
            {
                params: {
                    add_props: Array.isArray(addPropsQuery)
                        ? addPropsQuery.join(',')
                        : addPropsQuery
                },
                onUploadProgress
            }
        )
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const POST_ExperienceFromTemplate = async (
    uuid: string,
    // req: ExperienceRequest,
    addPropsQuery?: ExperienceAddPropsQuery | ExperienceAddPropsQuery[],
    onUploadProgress?: (progressEvent: ProgressEvent<EventTarget>) => void
) => {
    try {
        const res = await axios.post<ExperienceResponse>(
            `${API_ROOT}/api/v1/experience/${uuid}/create_from_template`,
            null,
            {
                params: {
                    add_props: Array.isArray(addPropsQuery)
                        ? addPropsQuery.join(',')
                        : addPropsQuery
                },
                onUploadProgress
            }
        )
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const PUT_Experience = async (
    id: number,
    req: Partial<ExperienceRequest>,
    addPropsQuery?: ExperienceAddPropsQuery | ExperienceAddPropsQuery[],
    onUploadProgress?: (progressEvent: ProgressEvent<EventTarget>) => void
) => {
    try {
        const res = await axios.put<ExperienceResponse>(
            `${API_ROOT}/api/v1/experience/${id}`,
            req,
            {
                params: {
                    add_props: Array.isArray(addPropsQuery)
                        ? addPropsQuery.join(',')
                        : addPropsQuery
                },
                onUploadProgress
            }
        )
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const deleteExperienceApi = async (
    uuid: string,
    delete_marker = false
) => {
    try {
        const res = await axios.delete<ExperienceResponse>(
            `${API_ROOT}/api/v1/experience/${uuid}/delete`,
            { params: { delete_marker } }
        )
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const deleteExperiencesApi = (req: {
    experience_uuids_marker_deletes: {
        experience_uuid: string
        delete_marker?: boolean
    }[]
}) =>
    axios
        .delete<XRServerResponse>(`${API_ROOT}/api/v1/experiences/delete`, { data: req })
        .then(res => res.data)
        .catch(r => {
            if (isBackendError(r)) {
                reportServerError(r)
                throw r.response.data.error
            }
            throw r
        })

export const GET_ExperienceQRBlob = async (
    uuid: string,
    token?: string,
    index: number = 1
) => {
    try {
        const res = await axios.get<Blob>(
            `${API_ROOT}/api/v1/experience/${uuid}/qrcode/${index}`,
            {
                responseType: 'blob',
                headers: {
                    ...getAuthorizationHeader(token)
                }
            }
        )
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const GET_ExperienceDeeplink = async (
    uuid: string,
    token?: string | null
) => {
    try {
        const response = await axios.get<XRServerResponse & { deeplink: string }>(
            `${API_ROOT}/api/v1/experience/${uuid}/deeplink`,
            {
                headers: {
                    ...getAuthorizationHeader(token)
                }
            }
        )
        return response.data.deeplink
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export interface ExperiencePreviewImage {
    created: string
    experience_uuid: string
    file_size: number | null
    modified: string
    name: string | null
    url: string
    uuid: string
}

export const POST_CreateExperiencePreview = async (img: Blob, uuid: string) => {
    try {
        const form = new FormData()
        form.append('file', img)
        form.append('experience_uuid', uuid)

        const res = await axios.post<
            XRServerResponse & { experience_preview_image: ExperiencePreviewImage }
        >(`${API_ROOT}/api/v1/experience_preview_image/create`, form, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return res.data.experience_preview_image
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const duplicateExperienceApi = (
    uuid: string,
    add_props?: ExperienceAddPropsQuery | ExperienceAddPropsQuery[]
) =>
    axios
        .post<XRServerResponse & { experience: Experience }>(
            `${API_ROOT}/api/v1/experience/${uuid}/create_copy`,
            null,
            {
                params: {
                    add_props: Array.isArray(add_props) ? add_props.toString() : add_props
                }
            }
        )
        .then(res => res.data.experience)
        .catch(error => {
            if (isBackendError(error)) {
                reportServerError(error)
                throw error.response.data.error
            }
            throw error
        })

//#endregion

//#region Marker and Asset
export interface MarkerResponse extends XRServerResponse {
    marker: Marker
}

export interface MarkerUpdateRequest {
    width?: number
    user_id?: number
    type?: string /** image|geo|multi */
    floor_to_center_height?: number | null
}

export interface AssetRequest {
    text?: string
    description?: string
    public?: boolean
    file?: File
    type?: AssetType
}

export interface AssetResponse extends XRServerResponse {
    asset: Asset
}

export interface AssetURLQuery {
    width?: number
    height?: number
    fit?: string //default cover,
    background?: { r: number; g: number; b: number; alpha: number }
}

export interface AssetURLResponse extends XRServerResponse {
    backup_url: string | null
    url: string | null
}

export interface PresignedAssetPostResponse extends XRServerResponse {
    presigned_post: {
        fields: {
            AWSAccessKeyId: string
            key: string
            policy: string
            signature: string
            success_action_redirect: string
            'x-amz-meta-uuid': string
        }
        url: string
    }
}

export interface AssetListResponse extends Pagination, XRServerResponse {
    assets: Asset[]
}

export interface AssetListQuery {
    add_props?: string
    expand?: string
    experience_id?: number
    file_ext?: string
    id?: number
    is_for_store?: boolean
    is_from_custom?: boolean
    is_preview_image?: boolean
    name?: string // case-insensitive, matches partials
    not_tag?: string // inverse of tag, case-insensitive, comma-seperated, AND-logic
    order_by?: string // default created
    page?: number
    per_page?: number // default 20
    reverse?: boolean // default false
    showcase_id?: number
    tag?: string // case-insensitive, comma-seperated, AND-logic
    team_id?: number
    type?: string
    user_id?: number
    uuid?: number
}

export const POST_MarkerUpload = async (
    file: File,
    blur?: boolean,
    onUploadProgress?: (progressEvent: ProgressEvent<EventTarget>) => void
) => {
    try {
        const data = new FormData()
        data.append('marker_file', file, file.name)
        if (blur) {
            data.append('also_upload_blur', 'true')
        }
        const res = await axios.post<MarkerResponse>(
            `${API_ROOT}/api/v2/marker/upload`,
            data,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress
            }
        )
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const PUT_MarkerUpdate = async (
    uuid: string,
    req: MarkerUpdateRequest,
    onUploadProgress?: (progressEvent: ProgressEvent<EventTarget>) => void
) => {
    const res = await axios.put<MarkerResponse>(`${API_ROOT}/api/v1/marker/${uuid}`, req, {
        onUploadProgress
    })
    return res.data
}

export const POST_SwapExperienceMarker = async (expID: number, file: File) => {
    try {
        const data = new FormData()
        data.append('file', file, file.name)
        data.append('delete_old_marker', 'false')
        data.append('also_upload_blur', 'true')
        const response = await axios.put<
            XRServerResponse & { experience: Experience }
        >(`${API_ROOT}/api/v1/experience/${expID}/marker_swap`, data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return response.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const GET_Assets = async (query?: AssetListQuery) => {
    const response = await axios.get<AssetListResponse>(`${API_ROOT}/api/v1/assets`, {
        params: query
    })
    return response.data
}

export const POST_Asset = async (
    req: AssetRequest,
    onUploadProgress?: (progressEvent: ProgressEvent<EventTarget>) => void
) => {
    const { text, description, public: _public, file, type } = req
    const formData = new FormData()
    if (text && text !== '') {
        formData.append('text', text)
    }
    if (description && description !== '') {
        formData.append('description', description)
    }
    if (_public !== undefined) {
        formData.append('public', _public.toString())
    }
    if (file) {
        formData.append('file', file)
    }
    if (type) formData.append('type', type)
    try {
        const res = await axios.post<AssetResponse>(
            `${API_ROOT}/api/v1/asset/create`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress
            }
        )
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const GET_PresignedAssetPOST = async () => {
    try {
        const res = await axios.get<PresignedAssetPostResponse>(
            `${API_ROOT}/api/v1/asset/presigned_post`
        )
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const POST_PresignedAssetPOST = async (
    file: File,
    type: AssetType,
    onUploadProgress?: (progressEvent: any) => void
) => {
    const { url, fields } = (await GET_PresignedAssetPOST()).presigned_post
    const formData = new FormData()
    Object.entries(fields).forEach(entry => formData.append(entry[0], entry[1]))
    formData.append('Content-Type', file.type)
    formData.append('x-amz-meta-file_ext', getFilenameExtension(file.name))
    formData.append('x-amz-meta-type', type as string)
    formData.append('file', file)

    const fetcher = axios.create()
    fetcher.defaults.headers.common = {}

    try {
        const response = await fetcher.post<AssetResponse>(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress
        })
        return response.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const GET_AssetURL = async (
    uuid: string,
    isMarker?: boolean,
    query?: AssetURLQuery,
    token?: string
) => {
    try {
        const res = await axios.get<AssetURLResponse>(
            `${API_ROOT}/api/v2/${isMarker ? 'marker' : 'asset'}/${uuid}/url`,
            {
                params: query,
                headers: {
                    ...getAuthorizationHeader(token)
                }
            }
        )
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const POST_PurchaseStoreAsset = async (uuid: string) => {
    try {
        const response = await axios.post<AssetResponse>(
            `${API_ROOT}/api/v1/asset/${uuid}/buy`
        )
        return response.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}
//#endregion

//#region FAQ
export interface FAQPayload {
    name: string
    text: string
}

export interface FAQ {
    created?: string
    id?: number
    modified?: string
    category?: string
    name?: string
    text: string
}

export interface FAQResponse extends XRServerResponse {
    faq_page: FAQ
}

export interface PaginatedFAQResponse extends XRServerResponse, Pagination {
    faq_pages: FAQ[]
}

export const GET_FAQPages = async (query?: {
    order_by?: string
    page?: number
    per_page?: number
    reverse?: boolean
}) => {
    try {
        const res = await axios.get<PaginatedFAQResponse>(`${API_ROOT}/api/v1/faq_pages`, {
            params: query
        })
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const POST_CreateFAQPage = async (payload: FAQPayload) => {
    try {
        const res = await axios.post<FAQResponse>(
            `${API_ROOT}/api/v1/faq_page/create`,
            payload
        )
        return res.data.faq_page
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

export const PUT_FAQPageUpdate = async (
    id: number,
    payload: Partial<FAQPayload>
) => {
    try {
        const res = await axios.put(`${API_ROOT}/api/v1/faq_page/${id}`, payload)
        return res.data
    } catch (r) {
        if (isBackendError(r)) {
            reportServerError(r)
            throw r.response.data.error
        }
        throw r
    }
}

//#endregion
